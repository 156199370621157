<template>
    <div id="app">
        <Header />
        <router-view v-bind:contents.sync="contents"></router-view>
        <Footer />
    </div>
</template>

<script>
    "use strict";

    import Footer from "@/components/TheFooter.vue";
    import Header from "@/components/TheHeader.vue";
    import common from "@/mixins/common";

    export default {
        "components": {
            Footer,
            Header,
        },
        "mixins": [
            common,
        ],
        data() {
            return {
                "contents": {
                    "flagLogin": false,
                    "flagSendData": false,
                    "flagUserUpdate": false,
                    "input": {
                        "client_id": "",
                        "name": "",
                        "tel": "",
                        "postal_code": "",
                        "address": "",
                        "mail_address": "",
                        "comment": "",
                        "send_name": "",
                        "send_tel": "",
                        "send_postal_code": "",
                        "send_address": "",
                        "receipt": "0",
                        "receipt_address": "",
                        "receipt_description": "",
                        "receipt_include": "0",
                        "account_registration": "0",
                        "subtotal": "",
                        "fee": "0",
                        "shipping_fee": "",
                        "total": "",
                        "payment_method": "",
                        "order": [],
                    },
                    "listItems": [],
                    "loginData": {},
                    "orderCount": {},
                    "user": {
                        "name": "",
                        "tel": "",
                        "postal_code": "",
                        "address": "",
                        "mail_address": "",
                        "password": "",
                    },
                },
                "displayOrders": [],
            };
        },
        created() {
            const process = [
                this.fetchingListItems(),
                this.fetchingDisplayOrders(),
            ];
            Promise.all(process).then(response => {
                const list = response[0];
                this.setDefaultOrders(list);
                this.sortListItems(list);
            });
        },
        "methods": {
            setDefaultOrders(list) {
                for (let data of list) {
                    // 注文数の初期値をset
                    this.$set(this.contents.orderCount, data.item_id, 0);
                }
            },
            sortListItems(list) {
                // 全商品データを全商品表示順にsort
                const order = this.displayOrders;
                list.sort((x, y) => {
                    return order.indexOf(x.item_id) - order.indexOf(y.item_id);
                });
                this.contents.listItems = list;
            },
            fetchingListItems() {
                // 全商品データ取得
                return common.$_axios.get(
                    `${common.$_configs.apiUrl}/items`,
                    this.$_requestConfig()
                ).then(response => {
                    const list = [];
                    for (let data of response.data) {
                        if (!data.deleted_at) {
                            list.push(data);
                        }
                    }
                    return list;
                }).catch(() => {
                    alert("商品の取得に失敗しました。");
                });
            },
            fetchingDisplayOrders() {
                // 全商品表示順データ取得
                return common.$_axios.get(
                    `${common.$_configs.apiUrl}/items/display_orders/read`,
                    this.$_requestConfig()
                ).then(response => {
                    this.displayOrders = response.data;
                }).catch(() => {
                    alert("商品表示順の取得に失敗しました。");
                });
            },
        },
    };
</script>
